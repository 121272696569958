var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks.remediations.workletModal.title')))]),_c('ax-button',{attrs:{"icon":""},on:{"click":_vm.cancelModal}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[_c('p',[_c('i18n',{attrs:{"path":"tasks.remediations.workletModal.description"}},[_c('strong',[_vm._v(_vm._s(_vm.cve))])])],1),_c('ax-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.worklets,"expanded":_vm.expandedRows,"show-select":"","single-select":"","column-select":false,"has-border":false},on:{"update:expanded":function($event){_vm.expandedRows=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.configuration.os_family",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',{staticClass:"d-flex justify-center"},[_c('ax-os-icon',{attrs:{"os-family":item.configuration.os_family}})],1)]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [(item.notes)?_c('v-icon',{attrs:{"color":_vm.teal},on:{"click":function($event){return _vm.toggleNotes(item)}}},[_vm._v(_vm._s(_vm.mdiNoteOutline))]):_vm._e()]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.buildPolicyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.remediations.workletModal.viewDetailsLink'))+" "),_c('v-icon',{staticClass:"tw-ml-2",attrs:{"color":_vm.teal,"small":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)]}},{key:"expanded-item",fn:function(ref){
var tableHeaders = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":tableHeaders.length}},[_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(item.notes))])])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('tasks.remediations.workletModal.noData')))]),_c('i18n',{attrs:{"path":"tasks.remediations.workletModal.createWorkletHelp"}},[_c('a',{attrs:{"href":"https://help.automox.com/hc/en-us/articles/5603324231700-Creating-a-Worklet","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.remediations.workletModal.createWorkletHelpLinkText'))+" ")])])]},proxy:true}]),model:{value:(_vm.selectedWorklets),callback:function ($$v) {_vm.selectedWorklets=$$v},expression:"selectedWorklets"}})],1),_c('v-card-actions',{staticClass:"ax-worklet-selection--action-bar"},[_c('div',{staticClass:"ax-worklet-selection--button-container"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":_vm.cancelModal}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c('ax-button',{attrs:{"disabled":_vm.selectedWorklets.length === 0,"mode":"primary"},on:{"click":_vm.remediate}},[_vm._v(_vm._s(_vm.$t('tasks.remediations.workletModal.remediate:button')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }